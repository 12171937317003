import { forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { BlockQuote, Stack, Text, Button, Dialog, Icons, Row } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ defaultFund }, ref) => {
  useImperativeHandle(ref, () => ({}))

  const { number } = defaultFund

  return (
    <Stack spacing={2}>
      <Text.Body>Prepare a cheque with the information below to be mailed to GiveWise Foundation Canada.</Text.Body>
      <BlockQuote>
        <Text.Bold>Payee</Text.Bold>
        <Text.Body>GiveWise Foundation Canada</Text.Body>
        <Text.Bold>Memo (Must be included)</Text.Bold>
        <Text.Body>Giving Fund #{number}</Text.Body>
        <Text.Bold>Mailing Address</Text.Bold>
        <Text.Body>
          3251 Boxwood Court <br />
          Abbotsford BC <br />
          V3G 0A6
        </Text.Body>
      </BlockQuote>

      <Text.Body>
        Your contribution will post to your account as soon as your cheque clears; however, your tax receipt will
        reflect the post mark date on the envelope.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContributionRecord,
      variables: {
        fundId,
        amount: amounts.total,
        paymentType: 2,
        description: '',
        taxReceipted: true,
        charityId: giftDetails.charityId,
        usedGiveGenius: true,
      },
      refetchQueries: [{ query: queries.funds.myFundActivity, variables: { fundId } }],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.createContributionRecord)
  return [contribution]
}

export function SuccessUI({ fund, grantTotal, charityId, charityName }) {
  const { number, id } = fund
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(`/funds/${id}/activity`)
  }
  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle
          sx={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%' }}
        >
          <Typography variant="h5">One More Step! Action Required to Complete Donation.</Typography>
        </DialogTitle>
      </Row>
      <DialogContent>
        <Text.Body sx={{ mb: 1 }}>
          Thank you for your donation to <strong>{charityName}</strong> (<strong>{charityId}</strong>). Your generosity
          is making a meaningful impact, and we&apos;re so grateful for your support.
        </Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          You will now need to mail a cheque in the amount of <strong>${grantTotal.toFixed(2)}</strong> to GiveWise
          order to complete this transaction.
        </Text.Body>
        <Text.Body sx={{ mb: 2 }}>
          <strong>Here are the instructions for preparing and sending a cheque:</strong>
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Payee:</strong> GiveWise Foundation Canada
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Value of Cheque:</strong> ${grantTotal.toFixed(2)}
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Memo (Must be included):</strong> Reference #{number}
        </Text.Body>
        <Text.Body>
          <strong>Mail Cheque To:</strong>
        </Text.Body>
        <Text.Body>3251 Boxwood Court</Text.Body>
        <Text.Body>Abbotsford BC</Text.Body>
        <Text.Body sx={{ mb: 3 }}>V3G 0A6</Text.Body>

        <Text.Body sx={{ mb: 3 }}>
          Once the cheque has been received and has cleared, the gift will be sent and you will receive an email
          notification.
        </Text.Body>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClick}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}
