import { forwardRef, useImperativeHandle, useRef } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Acknowledge, Button, Dialog, Link, Stack } from '../../../components'
import * as CreditCard from '../../../components/payment/credit-card'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { Text } from '../../../components'

export const PaymentUI = forwardRef(({ register }, ref) => {
  const cardApi = useRef()

  useImperativeHandle(ref, () => ({
    getPaymentMethodId: async (values) => {
      if (cardApi.current.isNewCard) {
        try {
          return await cardApi.current.postToStripeAndSavePaymentMethod({ multiUse: values.multiUse })
        } catch (e) {
          throw new Error(`This card cannot be used to finalize the transaction: ${e.message || e.type}`)
        }
      }

      return values.paymentMethodId
    },
  }))

  return (
    <Stack spacing={2}>
      <CreditCard.Select ref={cardApi} register={register} />
      <CreditCard.FeesNotice />
    </Stack>
  )
})

export async function getPaymentValues({ paymentMethodApi, values }) {
  const paymentMethodId = await paymentMethodApi.current.getPaymentMethodId(values)

  return {
    ...values,
    paymentMethodId,
  }
}

export async function createContributions({ client, fundId, giftDetails, payment }) {
  const { isRecurring, recurringInterval, processingDate } = giftDetails
  const { paymentMethodId } = payment

  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContribution,
      variables: {
        data: {
          paymentMethodId,
          fundId,
          amount: amounts.total,
          isRecurring,
          recurringInterval,
          processingDate,
          usedGiveGenius: true,
        },
      },
      refetchQueries: [
        { query: queries.funds.myFund, variables: { fundId } },
        { query: queries.funds.myFundActivity, variables: { fundId } },
        { query: queries.funds.myRecurring, variables: { fundId } },
        { query: queries.paymentMethods.myCreditCards },
      ],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.contribute)

  return [contribution]
}

export function SuccessUI({ grantTotal, charityName, fundId }) {
  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate(`/funds/${fundId}/activity`)
  }

  return (
    <Dialog open hideCloseButton>
      <Acknowledge.Success
        title="Thank You for Your Generosity!"
        content={
          <>
            <Text.Body>
              Your donation of <strong>${Number(grantTotal).toFixed(2)}</strong> to <strong>{charityName}</strong> has
              been successfully processed.
            </Text.Body>
            <Text.Body>
              Together, we&apos;re making an impact, and we&apos;re so grateful for your support! You can check on the
              status of your gift at anytime from the <Link to={`/funds/${fundId}/activity`}>Activity</Link> page.
            </Text.Body>
          </>
        }
      >
        <Button onClick={handleSignUpClick}>Done</Button>
      </Acknowledge.Success>
    </Dialog>
  )
}
