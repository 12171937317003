import { forwardRef, useImperativeHandle, useRef } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Acknowledge, Button, Dialog, Icons, Link, Row, Stack } from '../../../components'
import * as CreditCardGuest from '../../../components/payment/credit-card'
import { mutations } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ register }, ref) => {
  const cardApi = useRef()

  useImperativeHandle(ref, () => ({
    getPaymentMethodId: async (values) => {
      try {
        const paymentMethodId = await cardApi.current.postToStripe(values)
        return paymentMethodId
      } catch (e) {
        throw new Error(`This guest card cannot be used to finalize the transaction: ${e.message || e.type}`)
      }
    },
  }))

  return (
    <Stack spacing={2}>
      <CreditCardGuest.GuestForm ref={cardApi} register={register} />
      <CreditCardGuest.FeesNotice />
    </Stack>
  )
})

export async function getPaymentValues({ paymentMethodApi, values }) {
  const paymentMethodId = await paymentMethodApi.current.getPaymentMethodId(values)
  const { stepperState, ...other } = values
  return { ...other, paymentMethodId }
}

export async function createContributions({ client, fundId, giftDetails, payment, userId }) {
  const { isRecurring, recurringInterval, processingDate } = giftDetails
  const { paymentMethodId } = payment

  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createGuestContribution,
      variables: {
        userId,
        data: {
          paymentMethodId,
          fundId,
          amount: amounts.total,
          isRecurring,
          recurringInterval,
          processingDate,
          usedGiveGenius: true,
        },
      },
    })
    .then(({ data }) => data.guestContribute)

  return [contribution]
}

export function SuccessUI({ grantTotal, charityName }) {
  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate('/sign-up')
  }

  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle>
          <Typography variant="h5">Thank You for Your Generosity!</Typography>
        </DialogTitle>
      </Row>

      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Your donation of <strong>${grantTotal}</strong> to <strong>{charityName}</strong> has been successfully
          processed. Together, we&apos;re making an impact, and we&apos;re so grateful for your support!
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Here&apos;s what&apos;s next:
        </Typography>

        <Typography variant="body1" gutterBottom>
          To download your tax receipt and track your giving history, create a{' '}
          <Link to="/signup">GiveWise Giving Fund</Link>. It&apos;s quick, easy, and free!
        </Typography>

        <Typography variant="body1">With your GiveWise Giving Fund account, you can:</Typography>
        <ul>
          <li>
            <Typography variant="body1">Access all your tax receipts in one place.</Typography>
          </li>
          <li>
            <Typography variant="body1">Track your donations over time.</Typography>
          </li>
          <li>
            <Typography variant="body1">Set funds aside for future giving opportunities.</Typography>
          </li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSignUpClick}>
          Create Your Giving Fund
        </Button>
      </DialogActions>
    </Dialog>
  )
}
