import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Acknowledge, Button, Container, Stack, Text, Row } from '../components'
import { mutations } from '../graphql'
import { useSearchParams } from '../hooks'
import { Login } from '.'

function ConfirmSuccess({
  showLogin = false,
  charityUser = false,
  isFromStepper = false,
  alreadyConfirmed,
  afterLogin,
}) {
  if (alreadyConfirmed) {
    return (
      <Acknowledge.Success title="Account Confirmed" content="Your account is already confirmed.">
        <Button to="/login" state={{ afterLogin }}>
          Log In
        </Button>
      </Acknowledge.Success>
    )
  }

  if (isFromStepper) {
    return <Acknowledge.Success title="Account Verified" content="You are now safe to close this window." />
  }

  if (showLogin) {
    return (
      <Stack alignItems="center">
        <Row>
          <Login confirmAccountLogin charityUser={charityUser} afterLogin={afterLogin} />
        </Row>
      </Stack>
    )
  }

  return (
    <Acknowledge.Success
      title="Account Confirmed"
      content="Thank you for confirming your account. You can now log in with your credentials."
    >
      <Button to="/login" state={{ afterLogin }}>
        Log In
      </Button>
    </Acknowledge.Success>
  )
}

function ConfirmError({ afterLogin }) {
  return (
    <Acknowledge.Error title="Sorry!" content="We are unable to confirm your account">
      <Button to="/login" state={{ afterLogin }}>
        Back
      </Button>
    </Acknowledge.Error>
  )
}

export function ConfirmEmail() {
  const { userId, confirmationToken } = useParams()
  const [{ afterLogin, charityUser }] = useSearchParams()
  const [isFromStepper, setIsFromStepper] = useState(false)
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false)
  const [confirmEmail, { data, error }] = useMutation(mutations.user.confirmEmail, {
    onError: (_error) => {
      if (_error.message === 'already_confirmed') {
        setAlreadyConfirmed(true)
      }
    },
  })
  useEffect(() => {
    if (!confirmationToken) return
    confirmEmail({
      variables: {
        data: {
          confirmationToken,
          userId: Number(userId),
        },
        afterLogin,
      },
    })
    if (afterLogin && afterLogin.includes('/gift/charity/')) {
      setIsFromStepper(true)
    }
  }, [confirmationToken, confirmEmail])

  const headerText = () => {
    if (alreadyConfirmed) {
      return ''
    }
    if (charityUser) {
      return 'My Charity Account'
    }
    return 'My Giving Fund'
  }

  return (
    <Container maxWidth="lg">
      <Stack justifyItems="center">
        {!!error && (
          <Stack>
            <Text.H1>{headerText()}</Text.H1>
          </Stack>
        )}
        <Acknowledge.Switch
          success={
            (!!data || alreadyConfirmed) && (
              <ConfirmSuccess
                afterLogin={afterLogin}
                charityUser={charityUser}
                isFromStepper={isFromStepper}
                alreadyConfirmed={alreadyConfirmed}
                showLogin
              />
            )
          }
          error={!alreadyConfirmed && !!error && <ConfirmError afterLogin={afterLogin} />}
        />
      </Stack>
    </Container>
  )
}
