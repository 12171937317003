import { gql } from '@apollo/client'

export const createContribution = gql`
  mutation createContribution($data: CreateContributionInput!) {
    contribute(data: $data) {
      id
      amount
      fee
      netAmount
      state
    }
  }
`

export const createGuestContribution = gql`
  mutation createGuestContribution($userId: Int!, $data: CreateContributionInput!) {
    guestContribute(userId: $userId, data: $data) {
      id
      amount
      fee
      netAmount
      state
    }
  }
`

export const cancelScheduledContribution = gql`
  mutation cancelScheduledContribution($id: Int!) {
    cancelScheduledContribution(id: $id) {
      id
      amount
    }
  }
`

export const cancelRecurringContribution = gql`
  mutation cancelRecurrentContribution($id: Int!) {
    cancelRecurrentContribution(id: $id) {
      id
      amount
      frequency
      nextScheduledCharge
      state
      dayInMonth
      monthInYear
    }
  }
`

export const changeContributionState = gql`
  mutation changeContributionState($ids: [Int!]!, $state: String!) {
    changeContributionState(ids: $ids, state: $state)
  }
`

export const updateRecurrentContributionPaymentMethod = gql`
  mutation updateRecurrentContributionPaymentMethod($id: Int!, $paymentMethodId: Int!) {
    updateRecurrentContributionPaymentMethod(id: $id, paymentMethodId: $paymentMethodId) {
      id
    }
  }
`

export const userUpdateRecurrentContribution = gql`
  mutation userUpdateRecurrentContribution($data: UpdateRecurrentContributionInput!) {
    userUpdateRecurrentContribution(data: $data) {
      id
    }
  }
`

export const createContributionRecord = gql`
  mutation createContributionRecord(
    $fundId: Int!
    $amount: Float!
    $paymentType: Int!
    $description: String!
    $taxReceipted: Boolean!
    $state: Int
    $charityId: String!
    $usedGiveGenius: Boolean!
  ) {
    createContributionRecord(
      fundId: $fundId
      amount: $amount
      paymentType: $paymentType
      description: $description
      taxReceipted: $taxReceipted
      state: $state
      charityId: $charityId
      usedGiveGenius: $usedGiveGenius
    ) {
      id
      amount
      fee
      netAmount
      state
    }
  }
`

export const createGuestContributionRecord = gql`
  mutation createGuestContributionRecord(
    $userId: Int!
    $fundId: Int!
    $amount: Float!
    $paymentType: Int!
    $description: String!
    $taxReceipted: Boolean!
    $state: Int
    $charityId: String!
    $usedGiveGenius: Boolean!
  ) {
    createGuestContributionRecord(
      userId: $userId
      fundId: $fundId
      amount: $amount
      paymentType: $paymentType
      description: $description
      taxReceipted: $taxReceipted
      state: $state
      charityId: $charityId
      usedGiveGenius: $usedGiveGenius
    ) {
      id
      amount
      fee
      netAmount
      state
    }
  }
`

export const generateLetterofDirection = gql`
  mutation generateLetterofDirection($data: CreateSecuritiesInput!) {
    generateLetterofDirection(data: $data)
  }
`
