import { useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  BlockQuote,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  Stack,
  Text,
  useAlert,
  useDialog,
} from '../../../components'
import { fromAmountField } from '../../../utils'

export function DAF() {
  const { data: { me: { name } = { name: '' } } = {} } = useQuery(queries.user.me)
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const { number } = fund
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const [formData, setFormData] = useState({})
  const client = useApolloClient()
  const { fundId } = useParams()
  const [foundationDAFName, setFoundationDAFName] = useState('')

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0.0,
    },
  })

  const closeSuccessDialog = () => {
    resetForm()
    setFoundationDAFName('')
    closeDialog()
  }

  const onSubmit = async ({ amount }) => {
    try {
      await client.mutate({
        mutation: mutations.contributions.createContributionRecord,
        variables: {
          fundId: Number(fundId),
          amount: fromAmountField(amount),
          paymentType: 10,
          description: foundationDAFName,
          taxReceipted: false,
          charityId: '',
          usedGiveGenius: false,
        },
        refetchQueries: [
          { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
          { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
          { query: queries.paymentMethods.myLinkedBanks },
        ],
        awaitRefetchQueries: true,
      })
      console.log('onSubmit', { amount })
      console.log('foundationDAF', foundationDAFName)
      setFormData({ amount })
      openDialog()
    } catch (e) {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  const { errors = {} } = formState

  return (
    <Container maxWidth="lg">
      <Stack spacing={0}>
        <CardBox title="Transfer Funds From Another DAF or Foundation">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Fields.Text
                label="Amount"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputComponent: Fields.Amount,
                  inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: 0,
                  },
                }}
                {...register('amount', {
                  required: 'This field is required',
                  validate: {
                    positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                    min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
                  },
                })}
              />
              <Fields.Text
                label="Name of the Foundation/DAF"
                name="Foundation/DAF"
                value={foundationDAFName}
                onChange={(e) => setFoundationDAFName(e.target.value)}
                required
              />
              <Text.Body>
                Please request that the other Donor Advised Fund (DAF) or Foundation transfer funds to:
              </Text.Body>
              <BlockQuote>
                <Text.Bold>GiveWise Foundation Canada</Text.Bold>
                <Text.Body>
                  <strong>Charity Number</strong> #701032526 RR 0001
                </Text.Body>
              </BlockQuote>
              <Text.Body>
                Additionally, please include your full name and your Giving Fund number in the transfer request. You can
                copy and paste the account number below.
              </Text.Body>
              <BlockQuote>
                <Text.Bold>GiveWise Giving Fund #{number}</Text.Bold>
                <Text.Body>
                  <strong>Donor Name:</strong> {name}
                </Text.Body>
              </BlockQuote>
              <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
                Submit
              </Button>
              <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
            </Stack>
          </form>
        </CardBox>
      </Stack>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" />
        <div>
          <p>You have successfully initiated a transfer from another foundation or DAF.</p>
          <p>
            You will need to contact {foundationDAFName} to request the transfer. When submitting this request, please
            include the following information:
          </p>
          <p>
            <strong>Transfer Funds To:</strong>
            <br />
            GiveWise Foundation Canada
            <br />
            Charity Number #701032526RR0001
          </p>
          <p>
            <strong>Please Request Funds Be Credit To:</strong>
            <br />
            GiveWise Giving Fund #{number}
            <br />
            Donor Name: {name}
          </p>
        </div>
        <Button
          onClick={closeSuccessDialog}
          to={`/funds/${fundId}/activity/giving-wallet`}
          sx={{ width: 'fit-content', position: 'absolute', right: '32px', bottom: '32px' }}
        >
          Done
        </Button>
      </Dialog>
    </Container>
  )
}
