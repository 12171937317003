import { Checkbox, FormControlLabel, FormGroup, Icons } from '../../components'

export function Filters({
  showGrantRequests,
  setShowGrantRequests,
  showRGrantRequests,
  setShowRGrantRequests,
  showContributions,
  setShowContributions,
  showRContributions,
  setShowRContributions,
  showBalanceAdjustements,
  setShowBalanceAdjustements,
  showDafTransfers,
  setShowDafTransfers,
  showRDafTransfers,
  setShowRDafTransfers,
  showGiveGenius,
  setShowGiveGenius,
}) {
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& .MuiCheckbox-root': { paddingRight: '6px' /* down from 9px */ },
        '& .MuiFormControlLabel-label [data-icon]': { fontSize: '1em !important', color: 'var(--dark-blue)' },
      }}
    >
      {setShowGrantRequests && (
        <FilterItem
          checked={showGrantRequests}
          setChecked={setShowGrantRequests}
          icon={<Icons.GrantRequest />}
          text="Gifts"
        />
      )}
      {setShowRGrantRequests && (
        <FilterItem
          checked={showRGrantRequests}
          setChecked={setShowRGrantRequests}
          icon={<Icons.GrantRequest />}
          text="Recurring Gifts"
        />
      )}
      {setShowContributions && (
        <FilterItem
          checked={showContributions}
          setChecked={setShowContributions}
          icon={<Icons.Contribution />}
          text="Contributions"
        />
      )}
      {setShowRContributions && (
        <FilterItem
          checked={showRContributions}
          setChecked={setShowRContributions}
          icon={<Icons.Contribution />}
          text="Recurring Contributions"
        />
      )}
      {setShowBalanceAdjustements && (
        <FilterItem
          checked={showBalanceAdjustements}
          setChecked={setShowBalanceAdjustements}
          icon={<Icons.BalanceAdjustment />}
          text="Balance Adjustments"
        />
      )}
      {setShowDafTransfers && (
        <FilterItem
          checked={showDafTransfers}
          setChecked={setShowDafTransfers}
          icon={<Icons.GiftCard />}
          text="Shared Funds"
        />
      )}
      {setShowRDafTransfers && (
        <FilterItem
          checked={showRDafTransfers}
          setChecked={setShowRDafTransfers}
          icon={<Icons.Gift />}
          text="Recurrent Shared Funds"
        />
      )}
    </FormGroup>
  )
}

export function FilterItem({ icon, text, checked, setChecked }) {
  return (
    <FormControlLabel
      size="small"
      checked={checked}
      onChange={(event) => setChecked(event?.target?.checked)}
      control={<Checkbox />}
      label={
        <>
          {icon} {text}
        </>
      }
    />
  )
}
