import { forwardRef, useImperativeHandle } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

import { BlockQuote, Button, Dialog, Icons, Row, Stack, Text } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef(({ me, defaultFund }, ref) => {
  useImperativeHandle(ref, () => ({}))

  const name = me.name ?? ''
  const { number } = defaultFund

  return (
    <Stack spacing={2}>
      <Text.Body>E-Transfers can be performed from your online banking platform using the following details:</Text.Body>
      <BlockQuote>
        <Text.Bold>Recipient</Text.Bold>
        <Text.Body>
          GiveWise Canada <br />
          donations@givewise.ca
        </Text.Body>
        <Text.Bold>Notes</Text.Bold>
        <Text.Body>
          Giving Fund #{number} <br />
          Donor Name: {name}
        </Text.Body>
      </BlockQuote>
      <Text.Body>
        <strong>*IMPORTANT:</strong> In the notes section, you must remember to enter your first and last name and your
        GiveWise Giving Fund Number, so we can properly receive your funds into the correct Giving Fund.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContributionRecord,
      variables: {
        fundId,
        amount: amounts.total,
        paymentType: 3,
        description: 'e-Transfer',
        taxReceipted: true,
        charityId: giftDetails.charityId,
        usedGiveGenius: true,
      },
      refetchQueries: [{ query: queries.funds.myFundActivity, variables: { fundId } }],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.createContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, fund, grantTotal }) {
  const name = me.name ?? ''
  const { number, id } = fund
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(`/funds/${id}/activity`)
  }

  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle
          sx={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%' }}
        >
          <Typography variant="h5">One More Step! Action Required to Complete Donation.</Typography>
        </DialogTitle>
      </Row>
      <DialogContent>
        <Text.Body sx={{ mb: 2 }}>
          You will now need to create an eTransfer using your bank&apos;s online platform in the amount of{' '}
          {toAmountString(grantTotal)} and send it to:
        </Text.Body>
        <Text.Body>
          <strong>Recipient:</strong>
        </Text.Body>
        <Text.Body>GiveWise Canada</Text.Body>
        <Text.Body sx={{ mb: 1 }}>donations@givewise.ca</Text.Body>
        <Text.Body>
          <strong>Include in &quot;Message For Recipient&quot;:</strong>
        </Text.Body>
        <Text.Body>Giving Fund #{number}</Text.Body>
        <Text.Body sx={{ mb: 3 }}>Donor Name: {name}</Text.Body>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClick}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}
