import { gql } from '@apollo/client'

export const myFunds = gql`
  query MyFunds {
    me {
      id
      funds {
        id
        name
        number
      }
    }
  }
`

export const allMFunds = gql`
  query allMFunds {
    items: allMFunds {
      id
      name
      number
      taxReceiptYears
      dafTransfers {
        id
        __typename
      }
      createdAt
      totalBalance
      givingWalletBalance
      investmentWalletBalance
      managingUsers {
        id
        __typename
      }
      userId
      legacyId
      userName
      __typename
    }
    total: _allMFundsMeta {
      count
    }
  }
`

export const myFund = gql`
  query MyFund($fundId: Int) {
    me {
      id
      fund(id: $fundId) {
        id
        name
        number
        totalBalance
        givingWalletBalance
        investmentWalletBalance
        taxReceiptYears
        netTotalGifts
        netTotalGiftsYTD
        netTotalGrantRequests
        netTotalGrantRequestsYTD
        netTotalContributionsGiving
        netTotalContributionsInvestment
        netTotalContributionsGivingYTD
        netTotalContributionsInvestmentYTD
        isGenerosityFund
      }
    }
  }
`

export const myFundActivity = gql`
  query MyFundActivity($fundId: Int) {
    me {
      id
      fund(id: $fundId) {
        id
        name
        dafTransfers {
          id
          amount
          fee
          netAmount
          sentTo
          state
          receiveToken
          type
          description
          initiatingFundId
          initiatingUser {
            name
          }
          initiatingUserEmail
          receivingFundId
          receivingUser {
            name
          }
          createdAt
          canceledAt
          receivedAt
        }
        balanceAdjustments {
          id
          amount
          description
          walletType
          createdAt
        }
        grantRequests {
          id
          amount
          tip
          netAmount
          state
          description: grantNote
          processingNote
          createdAt
          processingDate
          anonymous
          recurrentGrantRequestId
          usedGiveGenius
          charity {
            id
            accountName
          }
        }
        contributions {
          id
          amount
          fee
          netAmount
          state
          description
          paymentType
          walletType
          createdAt
          receiptedDate
          taxReceipted
          recurrentContributionId
          usedGiveGenius
          paymentMethod {
            id
            paymentType
            details
          }
        }
        recentCharities {
          id
          accountName
          mailingAddress
          city
          province
        }
      }
    }
  }
`

export const myRecurring = gql`
  query MyRecurring($fundId: Int) {
    me {
      id
      fund(id: $fundId) {
        id
        recurrentGrantRequests {
          id
          amount
          tip
          grantNote
          frequency
          dayInMonth
          monthInYear
          nextScheduledGrant
          state
          anonymous
          createdAt
          charity {
            id
            accountName
          }
        }
        recurrentContributions {
          id
          amount
          frequency
          nextScheduledCharge
          state
          dayInMonth
          monthInYear
          createdAt
          paymentMethod {
            id
            paymentType
            details
          }
        }
        recurrentDafTransfers {
          id
          amount
          state
          frequency
          dayInMonth
          monthInYear
          nextScheduledTransfer
          createdAt
          receivingFund {
            id
            name
          }
        }
      }
    }
  }
`

export const generosityFund = gql`
  query generosityFund($fundId: Int!) {
    generosityFund(fundId: $fundId) {
      id
      name
    }
  }
`

export const searchGenerosityFunds = gql`
  query searchGenerosityFunds($searchString: String!) {
    searchGenerosityFunds(searchString: $searchString) {
      id
      name
    }
  }
`

export const canGiveAsGuest = gql`
  query canGiveAsGuest($email: String!) {
    canGiveAsGuest(email: $email)
  }
`
