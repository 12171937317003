export const toAmountString = (number, showAsDebit) => {
  if (!number && number !== 0) return ''
  const parsedNumber = typeof number === 'number' ? number : parseFloat(number, 10)
  if (Number.isNaN(parsedNumber)) return ''
  const formattedNumber = parsedNumber.toLocaleString('en-CA', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'CAD',
    currencyDisplay: 'symbol',
    style: 'currency',
  })
  return showAsDebit ? `${formattedNumber.replace('-', '')}` : formattedNumber
}

export const fromAmountString = (string) => {
  if (!string) return null
  if (typeof string === 'number') return string
  return parseFloat(string.replace(/[^0-9.-]+/g, ''), 10)
}

export const fromAmountField = (stringAmount) => {
  // Certain decimal places cause long tail decimals, which we need to round off
  let parsedAmount = parseFloat(stringAmount.replaceAll(',', ''))
  // toFixed returns a string, so we need to parse it to a float again
  parsedAmount = parseFloat(parsedAmount.toFixed(2))
  return parsedAmount
}
