import { forwardRef, useImperativeHandle } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BlockQuote, Fields, Stack, Text, Dialog, Row, Icons, Button } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef(({ me, defaultFund, register }, ref) => {
  useImperativeHandle(ref, () => ({}))

  const name = me?.name ?? 'Guest'

  return (
    <Stack spacing={2}>
      <Fields.Text
        label="Name of the Foundation/DAF"
        name="foundationDAFName"
        required
        {...register('foundationDAFName', {
          required: 'This field is required',
          defaultValue: '',
        })}
      />

      <Text.Body>
        Request that the Donor Advised Fund (DAF) or Foundation transfer funds to your Giving Fund after confirming your
        gift.
      </Text.Body>
      <BlockQuote>
        <Text.Bold>GiveWise Foundation Canada</Text.Bold>
        <Text.Body>
          <strong>Charity Number</strong> #701032526RR0001
        </Text.Body>
      </BlockQuote>
      <Text.Body>
        Additionally, please include your full name and your Giving Fund number in the transfer request. You can copy
        and paste the account number below.
      </Text.Body>
      <BlockQuote>
        <Text.Bold>GiveWise Giving Fund #000</Text.Bold>
        <Text.Body>
          <strong>Donor Name:</strong> {name}
        </Text.Body>
      </BlockQuote>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails, userId, payment }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createGuestContributionRecord,
      variables: {
        userId,
        fundId,
        amount: amounts.total,
        paymentType: 10,
        description: payment.foundationDAFName,
        taxReceipted: false,
        charityId: giftDetails.charityId,
        usedGiveGenius: true,
      },
    })
    .then(({ data }) => data.createGuestContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, user, grantTotal, charityId, charityName, payment }) {
  const name = me?.name ?? `${user.firstName} ${user.lastName}` ?? 'Guest'

  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate('/sign-up')
  }

  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle
          sx={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%' }}
        >
          <Typography variant="h5">One More Step! Action Required to Complete Donation.</Typography>
        </DialogTitle>
      </Row>

      <DialogContent>
        <Text.Body sx={{ mb: 2 }}>
          Dear <strong>{name}</strong>,
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          Thank you for your donation to <strong>{charityName}</strong> (<strong>{charityId}</strong>). Your generosity
          is making a meaningful impact, and we’re so grateful for your support.
        </Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          You will need to contact <strong>{payment.foundationDAFName}</strong> to initiate this transfer. When
          submitting this request, please include the following information:
        </Text.Body>
        <Text.Body>
          <strong>Transfer Funds To:</strong>
        </Text.Body>
        <Text.Body>GiveWise Foundation Canada</Text.Body>
        <Text.Body sx={{ mb: 1 }}>Registered Charity Number #701032526RR0001</Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Value of Transfer:</strong> ${grantTotal.toFixed(2)}
        </Text.Body>
        <Text.Body>
          <strong>Please Request Funds Be Credit To:</strong>
        </Text.Body>
        <Text.Body>Reference #{user.funds[0].number}</Text.Body>
        <Text.Body sx={{ mb: 3 }}>On Behalf of Donor Name: {name}</Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          Once the transfer has been received by GiveWise, the gift will be sent and you will receive an email
          notification.
        </Text.Body>
        <Text.H6 sx={{ mb: 1 }}>
          <strong>You’re Almost There!</strong>
        </Text.H6>
        <Text.Body>Add a password and create a Giving Fund so that you can:</Text.Body>
        <ul>
          <li>
            <Text.Body>Access your tax receipt.</Text.Body>
          </li>
          <li>
            <Text.Body>Set up recurring giving effortlessly.</Text.Body>
          </li>
          <li>
            <Text.Body>Streamline all your donations in one convenient place.</Text.Body>
          </li>
          <li>
            <Text.Body>Simplify tax time with a single tax receipt for all your giving.</Text.Body>
          </li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSignUpClick}>Add Password</Button>
      </DialogActions>
    </Dialog>
  )
}
