import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownFromLine,
  faArrowDownUpAcrossLine,
  faArrowUpFromLine,
  faBoxHeart,
  faBuildingColumns,
  faCalendarDay,
  faCalendarDays,
  faCalendarHeart,
  faCalendarPlus,
  faChartLineUp,
  faCheck,
  faCircleDollar,
  faCircleDollarToSlot,
  faCreditCard,
  faDatabase,
  faEnvelopeOpenDollar,
  faFaceParty,
  faGift,
  faGiftCard,
  faGrid,
  faHeadSideHeart,
  faHouseHeart,
  faImage,
  faList,
  faLock,
  faMinus,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyCheckDollar,
  faPiggyBank,
  faPlus,
  faSackDollar,
  faTrash,
  faTriangleExclamation,
  faUsersGear,
  faWallet,
} from '@fortawesome/pro-solid-svg-icons'
import { faHandHoldingHeart, faMoneyCheckPen } from '@fortawesome/pro-duotone-svg-icons'
import { Check, Circle, Close } from '@mui/icons-material'
// import { faBoxHeart as faBoxHeartThin } from '@fortawesome/pro-thin-svg-icons'
import {
  faBooks,
  faBoxHeart as faBoxHeartThin,
  faBuilding,
  faClipboardMedical,
  faCubes,
  faFarm,
  faHandHoldingDollar,
  faHandsPraying,
  faMailboxFlagUp,
  faMasksTheater,
  faPaw,
  faPeopleGroup,
  faScaleBalanced,
  faSeedling,
  faTilde,
  faTreeCity,
  faUserCircle,
  faUserGraduate,
  faHandHoldingHeart as handHeart,
} from '@fortawesome/pro-light-svg-icons'

import { titleCase } from '../../utils'
import { Box, Text, Tooltip } from '..'

export {
  Close,
  Visibility,
  VisibilityOff,
  Help,
  HelpOutline,
  ErrorOutline as Error,
  AccountCircle,
  AccountBalance,
  Paid,
  Favorite,
  FavoriteBorder,
  EventRepeat,
  Payments,
  Receipt,
  Person,
  CheckCircleOutline as Success,
  Announcement,
  AnnouncementOutlined,
  VolunteerActivism,
  AddCard,
  Search,
  FileOpen,
  Edit,
  NotificationImportant,
  Launch,
  Cancel,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  MoreHoriz,
  ExpandLess,
  ExpandMore,
  Check,
  DeleteForever,
} from '@mui/icons-material'

export function FaIcon(props) {
  return <FontAwesomeIcon style={{ fontSize: '1.5em' }} {...props} />
}
export function ShareFundsWithOthers({ primaryColor, secondaryColor, style, ...props }) {
  return (
    <FaIcon
      icon={faHandHoldingHeart}
      style={{
        ...style,
        ...(primaryColor && { '--fa-primary-color': primaryColor }),
        ...(secondaryColor && { '--fa-secondary-color': secondaryColor }),
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
      }}
      {...props}
    />
  )
}
export function Wallet(props) {
  return <FaIcon icon={faWallet} {...props} />
}
export function Lock(props) {
  return <FaIcon icon={faLock} {...props} />
}
export function GiftCard(props) {
  return <FaIcon icon={faGiftCard} {...props} />
}
export function DafTransfer(props) {
  return <FaIcon icon={faMoneyBillTransfer} {...props} />
}
export function Securities(props) {
  return <FaIcon icon={faMoneyBillTrendUp} {...props} />
}
export function CalendarDay(props) {
  return <FaIcon icon={faCalendarDay} {...props} />
}
export function Carousel(props) {
  return <FaIcon icon={faImage} {...props} />
}
export function CalendarDays(props) {
  return <FaIcon icon={faCalendarDays} {...props} />
}
export function Checkmark(props) {
  return <FaIcon icon={faCheck} {...props} />
}
export function MoneyCheckDollar(props) {
  return <FaIcon icon={faMoneyCheckDollar} {...props} />
}
export function CircleDollar(props) {
  return <FaIcon icon={faCircleDollar} {...props} />
}
export function CreditCard(props) {
  return <FaIcon icon={faCreditCard} {...props} />
}
export function EditPayment(props) {
  return <FaIcon icon={faMoneyCheckPen} {...props} />
}
export function Database(props) {
  return <FaIcon icon={faDatabase} {...props} />
}
export function Fund(props) {
  return <FaIcon icon={faPiggyBank} {...props} />
}
export function PayLink(props) {
  return <FaIcon icon={faEnvelopeOpenDollar} {...props} />
}
export function WarningTriangle(props) {
  return <FaIcon icon={faTriangleExclamation} {...props} />
}
export function Account(props) {
  return <FaIcon icon={faSackDollar} {...props} />
}
export function Investments(props) {
  return <FaIcon icon={faChartLineUp} {...props} />
}
export function User(props) {
  return <FaIcon icon={faHeadSideHeart} {...props} />
}
export function ManagingUserOnFund(props) {
  return <FaIcon icon={faUsersGear} {...props} />
}
export function Contribution(props) {
  return <FaIcon icon={faCircleDollarToSlot} {...props} />
}
export function Gift(props) {
  return <FaIcon icon={faGift} {...props} />
}
export function Grid(props) {
  return <FaIcon icon={faGrid} {...props} />
}
export function List(props) {
  return <FaIcon icon={faList} {...props} />
}
export function Grant(props) {
  return <FaIcon icon={faBoxHeart} {...props} />
}
export function GrantThin(props) {
  return <FaIcon icon={faBoxHeartThin} {...props} />
}
export function GrantRequest(props) {
  return <FaIcon icon={faGift} {...props} />
}
export function PaymentMethod(props) {
  return <FaIcon icon={faCreditCard} {...props} />
}
export function BalanceAdjustment(props) {
  return <FaIcon icon={faArrowDownUpAcrossLine} {...props} />
}
export function BalanceAdjustmentUp(props) {
  return <FaIcon icon={faArrowUpFromLine} {...props} />
}
export function BalanceAdjustmentDown(props) {
  return <FaIcon icon={faArrowDownFromLine} {...props} />
}
export function BuildingColumns(props) {
  return <FaIcon icon={faBuildingColumns} {...props} />
}
export function Charity(props) {
  return <FaIcon icon={faHouseHeart} {...props} />
}
export function RecurrentContribution(props) {
  return <FaIcon icon={faCalendarPlus} {...props} />
}
export function RecurrentGrantRequest(props) {
  return <FaIcon icon={faCalendarHeart} {...props} />
}
export function CircleUser(props) {
  return <FaIcon icon={faUserCircle} {...props} />
}
export function Business(props) {
  return <FaIcon icon={faBuilding} {...props} />
}
export function HandHoldingHeart(props) {
  return <FaIcon icon={handHeart} {...props} />
}

export function HandHoldingCircleDollar(props) {
  return <FaIcon icon={faHandHoldingDollar} {...props} />
}
export function UserGraduate(props) {
  return <FaIcon icon={faUserGraduate} {...props} />
}
export function Books(props) {
  return <FaIcon icon={faBooks} {...props} />
}
export function HandsPraying(props) {
  return <FaIcon icon={faHandsPraying} {...props} />
}
export function ClipboardMedical(props) {
  return <FaIcon icon={faClipboardMedical} {...props} />
}
export function ScaleBalanced(props) {
  return <FaIcon icon={faScaleBalanced} {...props} />
}
export function PeopleGroup(props) {
  return <FaIcon icon={faPeopleGroup} {...props} />
}
export function Seedling(props) {
  return <FaIcon icon={faSeedling} {...props} />
}
export function Farm(props) {
  return <FaIcon icon={faFarm} {...props} />
}
export function Paw(props) {
  return <FaIcon icon={faPaw} {...props} />
}
export function MasksTheater(props) {
  return <FaIcon icon={faMasksTheater} {...props} />
}
export function TreeCity(props) {
  return <FaIcon icon={faTreeCity} {...props} />
}
export function Cubes(props) {
  return <FaIcon icon={faCubes} {...props} />
}
export function CircleDollarToSlot(props) {
  return <FaIcon icon={faCircleDollarToSlot} {...props} />
}
export function Plus(props) {
  return <FaIcon icon={faPlus} {...props} />
}
export function Minus(props) {
  return <FaIcon icon={faMinus} {...props} />
}
export function Tilde(props) {
  return <FaIcon icon={faTilde} {...props} />
}
export function Trash(props) {
  return <FaIcon icon={faTrash} {...props} />
}
export function Mailbox(props) {
  return <FaIcon icon={faMailboxFlagUp} {...props} />
}

export function Boolean({ boolean = false }) {
  const trueBoolean = typeof boolean === 'boolean' ? boolean : Boolean(boolean)
  return trueBoolean ? (
    <Tooltip title="True">
      <Text.Body>
        <Check />
        <Text.SR>True</Text.SR>
      </Text.Body>
    </Tooltip>
  ) : (
    <Tooltip title="False">
      <Text.Body>
        <Close />
        <Text.SR>False</Text.SR>
      </Text.Body>
    </Tooltip>
  )
}
export function Charge(props) {
  return <FaIcon icon={faCircleDollarToSlot} {...props} />
}
export function GenerosityWorks(props) {
  return <FaIcon icon={faFaceParty} {...props} />
}

function stateColorSwitch(state) {
  switch (state) {
    case 'initiated':
    case 'complete':
    case 'active':
      return 'green'
    case 'received':
    case 'approved':
    case 'sent':
    case 'cleared':
    case 'posted':
      return 'blue'
    case 'canceled':
    case 'inactive':
    case 'failed':
    case 'charge exception':
      return 'red'
    case 'processing':
    default:
      return 'orange'
  }
}

export function State({ state, fontSize = 'auto' }) {
  if (!state) return null
  const stateCapitalized = state.charAt(0).toUpperCase() + state.slice(1)
  return (
    <Box
      sx={{
        py: '5px',
        px: '10px',
        fontSize,
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '13px',
        backgroundColor: 'var(--light-grey)',
      }}
    >
      <Circle sx={{ mr: '.5em', fontSize: '.85em', color: `var(--${stateColorSwitch(state)})` }} />
      {stateCapitalized}
    </Box>
  )
}

export function ActivityItem({ row: { __typename: typename, amount, type, showAsDebit, usedGiveGenius } = {} }) {
  switch (typename) {
    case 'GrantRequest':
    case 'RecurrentGrantRequest':
      return (
        <Tooltip title={usedGiveGenius ? 'GiveGenius Gift' : 'Gift'}>
          <Text.Body>
            <GrantRequest
              style={{
                fontSize: '1em',
                color: 'var(--yellow)',
              }}
            />
            <Text.SR>{usedGiveGenius ? 'GiveGenius Gift' : 'Gift'}</Text.SR>
          </Text.Body>
        </Tooltip>
      )
    case 'RecurrentDafTransfer':
      return (
        <Tooltip title="Recurrent Shared Funds">
          <Text.Body>
            <DafTransfer style={{ fontSize: '1em', color: showAsDebit ? 'var(--yellow)' : 'var(--dark-blue)' }} />
            <Text.SR>Recurrent Shared Funds</Text.SR>
          </Text.Body>
        </Tooltip>
      )
    case 'DafTransfer':
      return type === 'gift' ? (
        <Tooltip title="Shared Funds">
          <Text.Body>
            <GiftCard style={{ fontSize: '1em', color: showAsDebit ? 'var(--yellow)' : 'var(--dark-blue)' }} />
            <Text.SR>Shared Funds</Text.SR>
          </Text.Body>
        </Tooltip>
      ) : (
        <Tooltip title={`${titleCase(type?.concat(' '))}DAF Transfer`}>
          <Text.Body>
            <DafTransfer style={{ fontSize: '1em', color: showAsDebit ? 'var(--yellow)' : 'var(--dark-blue)' }} />
            <Text.SR>{type} DAF Transfer</Text.SR>
          </Text.Body>
        </Tooltip>
      )
    case 'BalanceAdjustment':
      return amount >= 0 ? (
        <Tooltip title="Balance Adjustment Credit">
          <Text.Body>
            <BalanceAdjustmentUp style={{ fontSize: '1em', color: 'var(--dark-blue)' }} />
            <Text.SR>Balance Adjustment Credit</Text.SR>
          </Text.Body>
        </Tooltip>
      ) : (
        <Tooltip title="Balance Adjustment Debit">
          <Text.Body>
            <BalanceAdjustmentDown style={{ fontSize: '1em', color: 'var(--yellow)' }} />
            <Text.SR>Balance Adjustment Debit</Text.SR>
          </Text.Body>
        </Tooltip>
      )
    case 'Contribution':
    case 'RecurrentContribution':
    default:
      return (
        <Tooltip title={usedGiveGenius ? 'GiveGenius Contribution' : 'Contribution'}>
          <Text.Body>
            <Contribution
              style={{
                fontSize: '1em',
                color: 'var(--dark-blue)',
              }}
            />
            <Text.SR>{usedGiveGenius ? 'GiveGenius Contribution' : 'Contribution'}</Text.SR>
          </Text.Body>
        </Tooltip>
      )
  }
}
