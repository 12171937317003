import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Container, Stack, Dialog, Link, Button, Text, Acknowledge } from '../../components'
import { toAmountString } from '../../utils'
import { PaymentMethodsMeta } from './payment'
import { GuestPaymentMethodsMeta } from './payment-guest'
import { onlyShowInDev } from './showUnfinishedFeatures'

export function SuccessStep({ charity, me, stepperState }) {
  useEffect(() => {
    if (onlyShowInDev) console.log(`success stepperState: ${JSON.stringify(stepperState, null, 2)}`)
  }, [stepperState])
  const { fundId, paymentMethodType, isRecurring, processingDate, grantTotal } = stepperState.success

  const PaymentMethodMeta =
    (stepperState.isGuest ? GuestPaymentMethodsMeta[paymentMethodType] : PaymentMethodsMeta[paymentMethodType]) ?? {}

  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(`/funds/${fundId}/activity`)
  }

  return (
    <Container maxWidth="sm" sx={{ flex: 1, py: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack direction="column" flexGrow={1} justifyContent="center">
        {PaymentMethodMeta.SuccessUI ? (
          <PaymentMethodMeta.SuccessUI me={me} user={stepperState.user} {...stepperState.success} />
        ) : (
          <Dialog open hideCloseButton>
            <Acknowledge.Success
              title={`Thank you! Your gift of ${toAmountString(grantTotal)} to ${charity.accountName} is now${' '}
                    ${isRecurring || processingDate ? 'scheduled' : 'pending'}.`}
              content={
                <Typography>
                  If you would like to make additional donations, get documents for tax credits, or manage monthly
                  donations you can return to this GiveWise site.
                </Typography>
              }
            >
              <Button onClick={handleOnClick}>Done</Button>
            </Acknowledge.Success>
          </Dialog>
        )}
      </Stack>
    </Container>
  )
}
